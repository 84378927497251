/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/light.always.css';


@import "theme/variables.scss";
@import "theme/colors.scss";

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

html {
    background: $light;
    font-size: 16px;
}

body {
    background: $lightestgrey-tint;
}

ion-refresher ion-refresher-content {
    --color: $grey;
}

// Removes safe-area-inset-top padding for app-header if opened in a modal
ion-modal app-header .header {
    padding-top: 0 !important;
}

//Fix for issue #134, TODO -- In the future we might want to make the color individual to each toat
ion-toast {
    --background: var(--ion-color-secondary); // Use your custom color variable
    --color: $light; // Setting text color to white
}

#hubspot-messages-iframe-container.widget-align-right {
    top: calc(env(safe-area-inset-top)) !important;
}

// Shimmer Animated BG for Placeholders
.bg-shimmer {
    -webkit-animation-duration: 2.2s;
    animation-duration: 2.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #E5F0FB;
    background: -webkit-linear-gradient(to right, #E5F0FB 4%, #DCE9F6 25%, #E5F0FB 36%);
    background: linear-gradient(to right, #E5F0FB 4%, #DCE9F6 25%, #E5F0FB 36%);
    -webkit-background-size: 1200px 100%;
    background-size: 1200px 100%;
}

/* Animation of Shimmer Background */
@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0
    }
    100% {
        background-position: 100% 0
    }
}
	
@keyframes shimmer {
    0% {
      background-position: -1200px 0
    }
    100% {
      background-position: 1200px 0
    }
}

.force-color-tint {
    color: $tint !important;
}